$.lui("comp",".comp_contact_cta",function (selector) {
    win.off('scroll.contactCta');

    win.on('scroll.contactCta',function () {
        const currentScroll = window.scrollY + window.innerHeight

        if(currentScroll > selector[0].offsetTop) {
            selector[0].classList.add('is--button-hidden')
        } else {
            selector[0].classList.remove('is--button-hidden')
        }
    })
});
