var reInitGCaptcha;

(function($) {
    lui_dialog.init(lui_dialog_callback);

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_btn", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        selector.lib_flickity();
    });

    $.lui("lib_popup", "[data-popup-show-after]", function(selector) {
        selector.lib_popup();
    });

    $.lui("lib_scroll", "[data-lib-scroll]", function(selector) {
        selector.on("click",function () {
            selector.closest("[data-ref-scroll]").next("section, div").anchor_anim(500);
        });
    });

    $.lui("lib_expand","[data-lib-expand]",function (selector) {
        selector.on("click","[data-lib-expand-toggle]",function (e) {
            e.preventDefault();
            let trigger = $(this),
                el = trigger.closest(selector),
                body = el.find("[data-lib-expand-target]"),
                options = el.data("lib-expand");

            if(el.hasClass("state--active")) {
                body.stop().slideUp(function () {
                    el.removeClass("state--active");
                    trigger.removeClass("state--active");
                    if (typeof options["text"] !== "undefined") {
                        el.find("[data-lib-expand-text]").text(options["text"]["inactive"]);
                    }
                });
            }
            else {
                trigger.addClass("state--active");
                if (typeof options["text"] !== "undefined") {
                    el.find("[data-lib-expand-text]").text(options["text"]["active"]);
                }
                body.stop().slideDown(function () {
                    el.addClass("state--active");
                });
            }
        });
    });

    $.lui("lib_isotope","[data-lib-isotope]",function (selector) {
        $.importScript(cdnjs.isotope,function () {
            cssLoaded(function () {
                if (!selector.data('isotope')) {
                    selector.isotope({
                        itemSelector: ".col--isotope",
                        percentPosition: true,
                        masonry: {
                            columnWidth: ".col--isotope"
                        }
                    });
                } else {
                    selector.isotope( 'appended', selector.children().not("[style]"));
                }
            });
        });
    });

    $.lui("lib_countup","[data-lib-countup]",function (selector) {
        $.importScript(cdnjs.countup,function () {
            selector.each(function () {
                let el = $(this),
                    parent = el.closest("[data-lib-reveal]"),
                    interval,
                    duration = 3,
                    data = el.data("lib-countup");

                let options = {
                    separator: ' ',
                    decimal: ',',
                };

                if(parseInt(data.value) > 100000) {
                    duration = 1.5;
                }

                let counter = new CountUp(el.get(0),0,parseInt(data.value),0,duration,options);

                interval = setInterval(function () {
                    if(parent.attr("data-lib-reveal") === "revealed") {
                        clearInterval(interval);
                        if (!counter.error) {
                            counter.start();
                        } else {
                            console.error(counter.error);
                        }
                    }
                },150);
            })
        });
    });

    $.lui("lib_gallery","[data-lib-gallery]",function (selector) {
        bodyLoaded(function(){
            $.importStyle(cdnjs.fancybox_css);
            $.importScript(cdnjs.fancybox, function(){
                $(selector).each(function(){
                    let id = $(this).data("lib-gallery");
                    $(this).fancybox({
                        selector: `[data-lib-gallery="${id}"] .lib--gallery-item`,
                        transitionEffect: "slide",
                        animationEffect: "zoom",
                        buttons: [
                            "close"
                        ]
                    });
                });
                doc.on("click","[data-lib-gallery-open]",function (e) {
                    e.preventDefault();
                    let id = $(this).data("lib-gallery-open");
                    if($(`[data-lib-gallery="${id}"]`).length) {
                        $.fancybox.open($(`[data-lib-gallery="${id}"] .lib--gallery-item`),{
                            smallBtn: false,
                            baseClass: "comp_gallery_hidden",
                            transitionEffect: "slide",
                            animationEffect: "zoom",
                            buttons: [
                                "close"
                            ],
                        });
                    }
                })
            });
        });
    });

    $.lui("lib_anchor","[data-lib-anchor-id]", function (selector) {
        let hash = window.location.hash;
        let getParam = window.location.search.substr(1);

        selector.each(function(){
            let elm = $(this);

            if(hash && elm.data("lib-anchor-id") === hash.replace('#','')) {
                cssLoaded(function(){
                    elm.anchor_anim(0);
                });
            } else if (getParam.indexOf("anchor=") !== -1 && elm.data("lib-anchor-id") === getParam.substr(getParam.indexOf("=")+1,getParam.length)) {
                cssLoaded(function(){
                    elm.anchor_anim(0);
                });
            }
        });
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let el = $(this);

            $.ajax({
                url: el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {
                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        lui_dialog.open(payload.dialog);
                    }
                });
            }).always(function () {
                frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");

            $.importScript(cdnjs.isotope,function () {
                cssLoaded(function () {
                    $(".row--grid").each(function () {
                        let row = $(this);
                        row.isotope({
                            itemSelector: ".col",
                            percentPosition: true,
                            masonry: {
                                columnWidth: ".col--sizer"
                            }
                        });
                    })
                });
            });
        }
    })();

    reInitGCaptcha = function() {
        $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    };

    reInitGCaptcha();
    setInterval(function(){reInitGCaptcha();}, 150000);

})(jQuery);
