$.lui("layout","#layout_header", function(selector) {
    let header = selector.find(".wrp_header_body");

    if (! $("#layout_nav").length) {
        if(!selector.hasClass("type--header-simplea")) {
            selector.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

            $.importScript(cdnjs.touchswipe, function() {
                $('.ssm-nav').lui_ssm();
            });
        }
        else {
            selector.after('<nav id="layout_nav" class="layout--nav-simplea"><div class="wrp_nav_content"></div></nav>');

            selector.on("click",'[data-action="toggleMenu"]',function (e){
                e.preventDefault;
                $('.layout--nav-simplea').toggleClass("ssm-nav-visible");
                selector.toggleClass("nav--visible");

                doc.off("click.simpleaNav");

                setTimeout(function (){
                    doc.on("click.simpleaNav",function (e) {
                        if(!$(e.target).closest(".layout--nav-simplea").length) {
                            $('.layout--nav-simplea').removeClass("ssm-nav-visible");
                            selector.removeClass("nav--visible");
                        }
                    })
                },300);
            });
        }

        let layout_nav = $(document).find("#layout_nav"),
            logo = header.find(".elm_header_logo").clone(),
            nav = header.find(".elm_header_nav").clone(),
            social = header.find(".elm_header_social").clone(),
            buttons = header.find(".elm_header_buttons").clone();

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(nav);
        layout_nav.find(".wrp_nav_content").append(social);
        layout_nav.find(".wrp_nav_content").append(buttons);
    }

    function initHeadroom(){
        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom(selector[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });
    };

    if (typeof Object.assign !== 'function') {
        $.importScript("https://polyfill.io/v3/polyfill.min.js?features=Object.assign",function () {
            initHeadroom();
        });
    }
    else {
        initHeadroom();
    }
});