$.lui("comp",".comp_process",function (selector) {

    $.importScript(cdnjs.scrollmagic, function () {
        cssLoaded(function () {
            //set elements around circle
            let circle = selector.find(".elm_body_spinner"),
                fields = circle.find(".elm_spinner_item"),
                angle = -7.85,
                step = (2 * Math.PI) / fields.length;

            placeElements();

            win.on("resize",debounce(function () {
                placeElements();
                // checkMobile();
            },250));

            function placeElements() {
                let radius = circle.outerWidth()/2 - 4,
                    width = circle.outerWidth(),
                    height = circle.outerHeight();

                fields.each(function() {
                    let x = Math.round(width / 2 + radius * Math.cos(angle) - $(this).width() / 2);
                    let y = Math.round(height / 2 + radius * Math.sin(angle) - $(this).height() / 2);
                    $(this).css({
                        left: x + 'px',
                        top: y + 'px'
                    });
                    angle += step;
                });
            }

            //scrollmagic
            let scrollHeight = selector.outerHeight() * 2;
            let text_els = selector.find(".elm_content_item");

            let controller = new ScrollMagic.Controller();
            let scene = new ScrollMagic.Scene({
                triggerElement: selector[0],
                triggerHook: 'onLeave',
                duration: scrollHeight,
            })
                .setPin(selector[0])
                .addTo(controller)
                .on("start end", function (e) {
                    if(e.state === "DURING") {
                        selector.addClass("is--fixed")
                    }
                    else {
                        selector.removeClass("is--fixed")
                    }
                });

            let scene_1 = new ScrollMagic.Scene({
                triggerElement: selector[0],
                triggerHook: 'onLeave',
                offset: scrollHeight / fields.length,
                duration: scrollHeight / fields.length
            })
                .addTo(controller)
                .on("start end", function (e) {
                    processStep(e,2);
                    if(e.scrollDirection === "REVERSE" && e.type === "start") {
                        circle.removeClass("state--1 state--2 state--3 state--4 state--5 state--6");
                        text_els.removeClass("state--active").eq(0).addClass("state--active");
                        fields.removeClass("state--active").eq(0).addClass("state--active");
                    }
                });

            let scene_2 = new ScrollMagic.Scene({
                triggerElement: selector[0],
                triggerHook: 'onLeave',
                offset: scrollHeight / fields.length * 2,
                duration: scrollHeight / fields.length
            })
                .addTo(controller)
                .on("start end", function (e) {
                    processStep(e,3)
                });
            let scene_3 = new ScrollMagic.Scene({
                triggerElement: selector[0],
                triggerHook: 'onLeave',
                offset: scrollHeight / fields.length * 3,
                duration: scrollHeight / fields.length
            })
                .addTo(controller)
                .on("start end", function (e) {
                    processStep(e,4)
                });

            function processStep(event, index) {
                circle.removeClass("state--1 state--2 state--3 state--4 state--5 state--6");
                circle.addClass("state--"+index,event.state === "DURING");
                text_els.removeClass("state--active").eq(index - 1).addClass("state--active");
                fields.removeClass("state--active").eq(index - 1).addClass("state--active");
            }

            selector.on("click","[data-process-step]",function (e) {
                e.preventDefault();
                let elm = $(this);
                let spacer;
                let duration = 500;
                if(selector.hasClass("is--fixed")) {
                    duration = 0;
                }

                if(typeof selector.closest(".scrollmagic-pin-spacer")[0] === "undefined") {
                    spacer = selector.closest(".scrollmagic-pin-spacer");
                }
                else {
                    spacer = selector.closest(".scrollmagic-pin-spacer")[0];
                }

                $("html, body").animate({
                    scrollTop: spacer.offsetTop + (scrollHeight / fields.length * parseInt(elm.data("process-step")))
                }, duration);

            })
        });
    });
});