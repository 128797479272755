$.lui("comp",".comp_dictionary",function (selector) {
    let searchTimeout = null
    const searchElement = selector.find("[data-target=\"search\"]")
    const searchInput = selector.find("[data-target=\"searchInput\"]")
    const searchResults = selector.find("[data-target=\"searchResults\"]")
    const searchCancel = selector.find("[data-target=\"searchCancel\"]")

    selector.on("input", searchInput, function (e) {
        const query = searchInput[0].value

        searchResults.removeClass("state--active")
        searchElement.addClass("state--active")

        if(query.length === 0) {
            searchElement.removeClass("state--active")
        }

        if (query.length >= 2) {
            clearTimeout(searchTimeout)

            const url = searchElement[0].dataset.actionUrl + query

            searchTimeout = setTimeout(() => {
                $.ajax({
                    url: url,
                    data: {ajax: +new Date},
                    dataType: "json",
                }).done(function (payload) {
                    searchElement[0].style.cursor = null
                    searchResults[0].innerHTML = payload.content
                    searchResults.addClass("state--active")

                    html.on("click.directorySearch", function (e) {
                        if($(e.target).closest(searchElement).length <= 0) {
                            html.off("click.directorySearch")
                            searchResults.removeClass("state--active")
                        }
                    })
                });
            }, 300)
        }
    })

    selector.on("click",".elm_nav_item[data-href]", function (e) {
        selector.find(".elm_nav_item.state--active").removeClass("state--active");
        $(this).addClass("state--active");
    })

    searchCancel.on("click", function (e) {
        e.preventDefault()

        searchInput[0].value = ""
        searchInput.trigger("input")
    })
});
