$.fn.lib_popup = function () {
    let selector = $(this);

    selector.each(function() {

        let showAfterSeconds = parseInt($(this).attr('data-popup-show-after'));
        let ajaxLink = $(this).attr('data-lib-dialog');

        setTimeout(function() {
            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: ajaxLink
            }).done(function(data) {
                if(typeof data.dialog !== 'undefined') {
                    lui_dialog.open(data.dialog, function() {
                    });
                    fn_ajaxHandler(data);
                }
            });
        },showAfterSeconds * 1000);

    });

};
