$.lui("comp",".comp_consultant_info",function (selector) {
    selector.on("click","[data-expand]",function () {
        let link = $(this),
            wsw = link.closest(".elm_body_bio").find(".part_ui_wsw");

        if(wsw.hasClass("is--collapsed")) {
            wsw.height(wsw[0].scrollHeight);
            link.find("span").text(link.data("expand")["less"]);
            setTimeout(function () {
                wsw.removeClass("is--collapsed");
            },300);
        }
        else {
            wsw.removeAttr("style").addClass("is--collapsed");
            link.find("span").text(link.data("expand")["more"]);
        }
    })
});