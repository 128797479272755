(function () {
    LibCookieConsent.init()
})();

$.lui("lib_cookies", ".part_cookieconsent", function(selector){
    selector = selector[0];
    
    if (document.querySelector('[data-no-cookies]') !== null) {
        ['performance', 'marketing'].forEach(type => LibCookieConsent.append(type))
        return
    }

    if (document.querySelector('.part_form_cookieconsent') !== null) {
        return
    }

    if (localStorage.getItem('lib-cookieconsent') === null || parseInt(localStorage.getItem('lib-cookieconsent-expire')) < Date.now()) {
        setTimeout(() => {
            selector.classList.add("state--active", "is--animate", "is--mobile-show");
            document.documentElement.style.overflow = "hidden";
        },500);
    }

    selector.querySelector('[data-lib-cookieconsent-approve]').addEventListener('click', () => {
        LibCookieConsent.set(['performance', 'marketing'])
        selector.classList.remove('is--animate')

        setTimeout(() => {
            selector.classList.remove("state--active");
            document.documentElement.style.overflow = null;
            selector.remove()
        }, 500)
    })

    selector.querySelector('[data-lib-cookieconsent-decline]').addEventListener('click', () => {
        LibCookieConsent.set(['performance'])
        selector.classList.remove("is--animate");

        setTimeout(() => {
            selector.classList.remove("state--active");
            document.documentElement.style.overflow = null;
            selector.remove()
        }, 500)
    })
});

$.lui("lib_cookies_form", ".part_form_cookieconsent", function(selector){
    const type = localStorage.getItem('lib-cookieconsent')
    selector = selector[0];

    if (type !== null) {
        selector.querySelectorAll('input:not([disabled])').forEach(input => input.checked = false)

        JSON.parse(type).forEach(type => {
            if (selector.querySelector(`input[value="${type}"]`) !== null) {
                selector.querySelector(`input[value="${type}"]`).checked = true
            }
        })
    }

    selector.addEventListener('submit', e => {
        e.preventDefault()

        let type = []

        selector.querySelectorAll('input:not([disabled])').forEach(input => {
            input.checked && type.push(input.value)
        })

        LibCookieConsent.set(type)
        location.reload()
    })
});
