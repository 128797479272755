$.lui("comp",".comp_location",function (selector) {
    const map = selector.find(".elm_comp_map");

    let select = selector.find("[data-select-region]"),
        regions = map.find("[data-region]");

    map.on("click","[data-region]",function (e) {
        e.stopPropagation();
        e.preventDefault();
        select.val($(this).data("region")).trigger("change");
    });

    select.on("change",function () {
        regions.removeClass("state--active");
        regions.filter("[data-region=\"" + select.val() + "\"]").addClass("state--active");

        let form = select.closest('form');

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    })
});