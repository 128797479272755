$.fn.lib_chart = function () {
    let selector = $(this);

    $.importScript(cdnjs.chart, function(){
        let chart_data = selector.data("chart");

        let options = {
            pointRadius: 7,
            borderWidth: 2,
            fill: 'rgba(0, 0, 0, 0)',
            lineTension: 0,
            backgroundColor: 'rgb(255, 255, 255)',
            pointHoverRadius: 7,
        };

        let datasets = [];
        chart_data.datasets.forEach(function (item, index) {
            let values = [];
            item.data.forEach(function (item) {
                values.push(parseInt(item.value.replace(" ","")));
            });
            let el = {
                data: values,
                borderColor: item.color,
                hoverBackgroundColor: item.color,
            };
            $.extend(el, options);
            datasets.push(el);
        });

        let config = {
            type: 'line',

            data: {
                labels: chart_data.labels,
                datasets: datasets
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                hover: {
                    mode: "nearest"
                },
                tooltips: {
                    mode: 'nearest',
                    displayColors: false,
                    backgroundColor: 'rgba(48, 65, 84, 0.85)',
                    titleFontSize: 10,
                    bodyFontSize: 18,
                    bodySpacing: 10,
                    titleSpacing: 10,
                    yPadding: 10,
                    xPadding: 12,
                    bodyFontFamily: "'JohnSans','sans-serif'",
                    titleFontFamily: "'JohnSans','sans-serif'",
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return chart_data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].label;
                        },
                        label: function(tooltipItem, data) {
                            if(typeof chart_data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].tooltip !== "undefined") {
                                return chart_data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].tooltip;
                            }
                            else {
                                return chart_data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].value;
                            }
                        }
                    }
                },
                line: {
                    borderColor: 'rgb(255,255,255)'
                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: true
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true,
                            stepSize: 200000,
                            callback: function (value, index, values) {
                                return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
                            }
                        }
                    }]
                }
            }
        };

        let interval = setInterval(function(){
            if (selector.find("[data-lib-reveal]").attr("data-lib-reveal") === "revealed") {
                clearInterval(interval);
                let ctx = selector.find('canvas')[0].getContext('2d');
                new Chart(ctx, config);
            }
        }, 100);
    });
}
