let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.10.3/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "scrollmagic": "https://cdn.jsdelivr.net/npm/scrollmagic@2.0.7/scrollmagic/minified/ScrollMagic.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.1/dist/flickity.pkgd.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "isotope": "https://cdn.jsdelivr.net/npm/isotope-layout@3.0.6/dist/isotope.pkgd.min.js",
    "chart": "https://cdn.jsdelivr.net/npm/chart.js@2.9.3/dist/Chart.min.js",
    "countup": "https://cdn.jsdelivr.net/npm/countup.js@1.9.3/dist/countUp.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css",
    "googlemaps": "https://maps.googleapis.com/maps/api/js?key={apikey}",
    "noUi": "https://cdn.jsdelivr.net/npm/nouislider@15.5.1/dist/nouislider.min.js",
    "noUi_css": "https://cdn.jsdelivr.net/npm/nouislider@15.5.1/dist/nouislider.min.css"
};
