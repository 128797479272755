$.lui("comp",".comp_calculator",function (selector) {
    const inputs = [...selector[0].querySelectorAll('[data-calculator-target~="input"]')]
    const inputsType = [...selector[0].querySelectorAll('[data-calculator-target~="inputType"]')]
    const form = selector[0].querySelector('[data-calculator-target~="form"]')
    const token = selector[0].dataset.calculatorToken
    const lang = document.documentElement.getAttribute('lang')
    const url = 'https://www.epartners.cz/renta/api/duchod/renta'

    //Fund type toggle
    inputsType.forEach(input => {
        input.addEventListener('change', function () {
            if(input.value === "1") {
                selector[0].querySelector('[data-calculator-target="typeDPS"]').removeAttribute('hidden')
                selector[0].querySelector('[data-calculator-target="typeTF"]').setAttribute('hidden','')
            }
            if(input.value === "0") {
                selector[0].querySelector('[data-calculator-target="typeDPS"]').setAttribute('hidden','')
                selector[0].querySelector('[data-calculator-target="typeTF"]').removeAttribute('hidden')
            }
        })
    })

    //Submit form when anything changes
    inputs.forEach(input => {
        input.addEventListener('change', debounce(function () {
            form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
        }, 150))
    })

    //Trigger for initial calculation
    inputs[0].dispatchEvent(new Event('change', { bubbles: true, cancelable: true }))

    //Call to API and handle results
    form.addEventListener('submit',(e) => {
        e.preventDefault()

        const formData = new FormData(form)
        const parameters = {"Parameters":{}}

        for(const pair of formData) {
            parameters['Parameters'][pair[0]] = parseFloat(pair[1])
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'text/json'
            },
            body: JSON.stringify(parameters)
        }).then((response) => {
            if(!response.ok) {
                throw Error(response.statusText)
            }
            return response.json()
        }).then((payload) => {
            const data = (payload['Data'])

            // Fake data for testing w/o API
            // const data = {
            //     "Result": {
            //         "StarobniDuchodVyse": 19471.58,
            //         "NepokrytaVyseRenty": 20528.42,
            //         "ZnehodnocenaNepokrytaVyseRenty": 68433.36,
            //         "CilovaCastka": 11032597.53,
            //         "PokrytaCastka": 532597.53,
            //         "VyseVkladuProPokrytiRenty": 7992.96
            //     },
            // }
            //
            // data['Parameters'] = parameters['Parameters']

            // set values to elements
            for(const key in data["Result"]) {
                const elmResult = selector[0].querySelector(`[data-calculator-target="${key}"]`)

                if(elmResult !== null) {
                    elmResult.innerHTML = data["Result"][key].toLocaleString(lang, {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })
                }
            }

            //set progress bars
            if(selector[0].querySelector('[data-calculator-target="progressHave"]') !== null && selector[0].querySelector('[data-calculator-target="progressRemaining"]') !== null) {
                const progressHave = Math.round(data['Result']['PokrytaCastka'])
                const progressTotal = Math.round(data['Result']['CilovaCastka'])
                const progressRemaining = Math.max(progressTotal - progressHave, 0)
                const progressHaveCol = selector[0].querySelector('[data-calculator-target="progressHave"]').closest('.col')

                selector[0].querySelector('[data-calculator-target="progressHave"]').innerHTML = progressHave.toLocaleString(lang, {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                })
                selector[0].querySelector('[data-calculator-target="progressRemaining"]').innerHTML = progressRemaining.toLocaleString(lang, {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                })

                const setProgressWidth = function setProgressWidth() {
                    progressHaveCol.style.width = null
                    progressHaveCol.style.width = `${Math.max((progressHave / progressTotal), (progressHaveCol.clientWidth / progressHaveCol.closest('.row--flex').clientWidth)) * 100}%`
                }

                // jQuery bullshit, pretty sure uglify is breaking the function handler being saved
                win.off('resize.compCalculator')
                win.on('resize.compCalculator', function () {
                    setProgressWidth()
                })

                setProgressWidth()
            }

            //draw chart
            if(selector[0].querySelector('[data-calculator-target="chart"]') !== null) {
                //test data
                // data['Parameters']['PozadovanaVyseRenty'] = 25000
                // data['Result']['CilovaCastka'] = 500000
                // data['Result']['PokrytaCastka'] = 300000
                // data['Result']['StarobniDuchodVyse'] = 5000

                const StarobniDuchodVysePercentage = Math.min(1,(data['Result']['StarobniDuchodVyse'] / data['Parameters']['PozadovanaVyseRenty']))
                const CilovaCastkaPercentage = 1 - StarobniDuchodVysePercentage
                const PokrytaCastkaPercentage = (data['Result']['PokrytaCastka'] / data['Result']['CilovaCastka']) * CilovaCastkaPercentage
                const PokrytaCastka = data['Parameters']['PozadovanaVyseRenty'] * (isNaN(PokrytaCastkaPercentage) ? 0 : PokrytaCastkaPercentage)

                const chartData = {
                    "StarobniDuchodVyse": data['Result']['StarobniDuchodVyse'],
                    "PokrytaCastka": PokrytaCastka,
                    "VyseVkladuProPokrytiRenty": Math.max(0, data['Parameters']['PozadovanaVyseRenty'] - data['Result']['StarobniDuchodVyse'] - PokrytaCastka)
                }

                for(const key in chartData) {
                    const elmResult = selector[0].querySelector(`[data-calculator-target="${key}"]`)

                    if(elmResult !== null) {
                        elmResult.innerHTML = chartData[key].toLocaleString(lang, {
                            style: 'decimal',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                    }
                }

                $.importScript(cdnjs.chart, function () {
                    const ctx = selector[0].querySelector('[data-calculator-target="chart"]').getContext('2d')
                    const config = {
                        type: 'doughnut',

                        data: {
                            datasets: [{
                                data: [chartData['PokrytaCastka'],chartData['StarobniDuchodVyse'],chartData['VyseVkladuProPokrytiRenty']],
                                backgroundColor: [
                                    '#0D9FB5',
                                    '#FFA479',
                                    '#FF6320'
                                ],
                                borderWidth: 0,
                            }]
                        },
                        options: {
                            responsive: true,
                            aspectRatio: 1,
                            cutoutPercentage: 45,
                            legend: {
                                display: false
                            },
                            title: {
                                display: false
                            },
                            tooltips: {
                                enabled: false
                            },
                            events: [],
                            rotation: 1.25 * Math.PI
                        }
                    }
                    const chart = new Chart(ctx, config);
                })
            }
        }).catch((error) => {
            lui_dialog.open(selector[0].querySelector('[data-calculator-target="errorDialog"]').innerHTML)
            console.error(error)
        })
    })
});
