$.lui("comp",".comp_contact_branches",function (selector) {
    selector.on("change","[data-select-region]",function (e) {
        let form = $(this).closest('form');

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });

    selector.on("change","[data-select-city]",function (e) {
        let form = $(this).closest('form');

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });
});