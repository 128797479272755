$.lui("lib_range", ".part_ui_range", function(selector){
    const lang = document.documentElement.lang

    if(selector.length > 0) {
        $.importStyle(cdnjs.noUi_css)
        $.importScript(cdnjs.noUi, function () {
            selector.each(function () {
                const elm = $(this)[0];
                const elmSlider = elm.querySelector('[data-ui-range-target="slider"]')

                if(elmSlider !== null && typeof elmSlider.noUiSlider === 'undefined') {
                    const options = JSON.parse(elm.dataset.uiRangeOptions)
                    const elmInput = elm.querySelector('[data-ui-range-target="input"]')
                    const elmInputPretty = elm.querySelector('[data-ui-range-target="inputPretty"]')

                    noUiSlider.create(elmSlider, {
                        start: parseFloat(elmInput.value),
                        range: {
                            min: parseFloat(options.min),
                            max: parseFloat(options.max)
                        },

                        step: parseFloat(options.step),
                        connect: 'lower'
                    })

                    elmSlider.noUiSlider.on('update', (values, handle) => {
                        const value = parseFloat(values[handle])

                        if (!handle) {
                            elmInputPretty.value = value.toLocaleString(lang, {
                                style: 'decimal',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })
                        }
                    })

                    elmSlider.noUiSlider.on('set', (values, handle) => {
                        const value = parseFloat(values[handle])

                        if (!handle) {
                            elmInput.value = value
                            elmInput.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }))
                        }
                    })

                    elmInputPretty.addEventListener('focus', () => {
                        elmInputPretty.value = elmInputPretty.value.replace(/\s/g,'')
                    })

                    elmInputPretty.addEventListener('change', () => {
                        if (elmInputPretty.value !== '') {
                            elmSlider.noUiSlider.set(parseFloat((elmInputPretty.value).replace(/\s/g, '')))
                        }
                    })
                }
            })
        })
    }
});
