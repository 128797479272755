window.lui_map_responses = {};

$.lui("comp", ".comp_map", function(selector) {
    let self = selector[0];
    let options = JSON.parse(self.dataset.options),
        markersUrl = self.dataset.markers;

    inView(self, () => {
        $.importScript(cdnjs.googlemaps.replace("{apikey}", options["apikey"]), () => {
            let coords = options["center"].split(/,\s/);
            let bounds = new google.maps.LatLngBounds();
            let markers_temp = [];
            let center = new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]));
            let info_template = "";

            let map = new google.maps.Map(self.querySelector('[data-target="map"]'), {
                center: center,
                zoom: 14,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                clickableIcons: false,
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "stylers": [
                            {
                                "color": "#000000"
                            },
                            {
                                "visibility": "off"
                            },
                            {
                                "weight": 1.5
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#000000"
                            },
                            {
                                "visibility": "on"
                            },
                            {
                                "weight": 1
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e5e5e5"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dadada"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#c9c9c9"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    }
                ]
            });

            let icon = {
                url: '/img/marker.png',
                scaledSize: new google.maps.Size(40, 40),
                anchor: new google.maps.Point(20, 20)
            };

            if(typeof self.querySelector('[data-target="infoWindow"]') !== "undefined") {
                info_template = self.querySelector('[data-target="infoWindow"]').innerHTML;
            }

            let infoWindow = new google.maps.InfoWindow({
                content: "",
            });

            bounds.extend(center);

            if(typeof markersUrl !== "undefined") {
                function getMarkers(url,callback) {
                    if (typeof window.lui_map_responses[url] === "undefined") {
                        fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
                            return response.json();
                        }).then((payload) => {
                            window.lui_map_responses[url] = payload;
                            callback(payload);
                        });
                    }
                    else {
                        callback(window.lui_map_responses[url]);
                    }
                }

                function generateMarker(item) {
                    let title = item["title"];
                    let point = new google.maps.LatLng(parseFloat(item["latitude"]), parseFloat(item["longitude"]));
                    let content = info_template;
                    Object.entries(item).forEach((key) => {
                        content = content.replaceAll(`*${key[0]}*`, key[1])
                    });

                    let marker = new google.maps.Marker({
                        title: title,
                        map: map,
                        position: point,
                        icon: icon,
                    });
                    bounds.extend(point);
                    markers_temp.push(marker);

                    function showInfo(marker) {
                        infoWindow.close();
                        infoWindow.setContent(content);
                        infoWindow.open(map, marker);
                    }

                    marker.addListener('click', () => {
                        showInfo(marker);
                    });
                }

                map.addListener("click", () => {
                    infoWindow.close();
                })

                getMarkers(markersUrl, (payload) => {
                    bounds = new google.maps.LatLngBounds();

                    for (let item of payload) {
                        generateMarker(item);
                    }

                    if (payload.length > 1) {
                        map.fitBounds(bounds);
                    } else {
                        map.setCenter({lat: payload[0].latitude, lng:payload[0].longitude});
                    }
                });
            }
        });
    })
});
