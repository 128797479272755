$.lui("comp", ".comp_contact_consultants", function (selector) {

    let searchRunning;
    selector.on("input", "[data-search]", debounce(function (e) {
        let form = $(this).closest('form');

        if (searchRunning) {
            searchRunning.abort();
        }

        searchRunning = $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    }, 300));

    selector.on('submit', 'form', function (e) {
        e.preventDefault();
    });
});