$.lui("comp",".comp_visual_square",function (selector) {
    selector.on("click", "[data-toggle-audio]", function(){
        let video = selector.find("video");
        let elm = $(this);
        if( video.prop('muted') ) {
            video.prop('muted', false);
            elm.addClass("state--active");
        } else {
            video.prop('muted', true);
            elm.removeClass("state--active");
        }
    });
});