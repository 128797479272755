$.lui("comp",".comp_media_reports",function (selector) {
    let body = selector.find(".elm_comp_body");

    selector.on("click","[data-expand]",function () {
        if(body.hasClass("is--collapsed")) {
            body.height(body[0].scrollHeight);
            setTimeout(function () {
                body.removeClass("is--collapsed");
                selector.addClass("state--active");
            },300);
        }
        else {
            selector.removeClass("state--active");
            body.removeAttr("style").addClass("is--collapsed");
        }
    })
});